.sign-up-button {
  display: inline-block;
  background-color: #ffa500;
  color: #ffffff;
  padding: 10px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sign-up-button:hover {
  background-color: #e59400;
}

.scaled-image {
  max-width: 200px;
}

.jbf{background-color:black;}



