.htmlItem{
  
    
    font-size: 1.3em;
}

.htmlItem img {
  width: 80%;
  height: auto; /* Maintain the aspect ratio */
}


.section {
  margin-bottom: 20px;
}

.htmlItem table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.htmlItem table, .htmlItem th, .htmlItem td {
  border: 1px solid #ddd;
}

.htmlItem th, .htmlItem td {
  padding: 15px;
  text-align: center;
  font-size: 16px;
  color: #555;
}

.htmlItem th {
  background-color: #f2f2f2;
  color: #333;
}

.htmlItem td img {
  max-width: 100px;
  height: auto;
}

.htmlItem td {
  vertical-align: middle;
}

/* Add some hover effect */
.htmlItem table tr:hover {
  background-color: #f1f1f1;
}

.main-button-green{
  color: white; /* White text */
  background-color: #28d757; /* Green background */
}
.main-button-green:hover {
  background-color: #22a144; /* Darker green on hover */
}
.main-button-green-old{
  color: white; /* White text */
  background-color: #0879004a; /* Green background */
}

.main-button-large-text{
  font-size:1.5em;
  font-weight:bolder;
}


.main-button {
  border: none; /* Remove border */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.primary-button {
  
 
}

.button4-container {
  
  display: inline-flex;
  align-items: center;
  gap: -100; /* Increased space to move text significantly to the right */
  cursor: pointer; /* Make the entire container clickable */
  transition: transform 0.2s; /* Apply the scaling transition */
}

.button4-container:hover {
  transform: scale(1.3); /* Scale both the text and the arrow together */
}

.font-small {
  font-size: 0.75em; /* 75% of the parent element's font size */
}

.button4-text {  
  font-size: 24px; /* Larger font for better visibility */
  font-family: Arial, sans-serif; /* Font family */
  font-weight: bold; /* Stronger text */
  color: #0ec60e; /* A nice medium green color */
  text-shadow: 0px 0px 2px #000; /* Subtle black outline for visibility */
  margin: 0;
}

.button4 {
  background-color:red;
  display: inline-block;
  width: 100px; /* Adjust to match your image dimensions */
  height: 100px; /* Adjust to match your image dimensions */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.previous-button4 {
  background-image: url('../public/images/buttons/back4.png');
}
.next-button4 {
  background-image: url('../public/images/buttons/next4.png');
}

.article-title-container{
  background-color:#4a47f9;
  color:white;
  text-align: center;
  padding:20px;
  font-weight:bolder;
  font-size:1.8em;
}

.spark-close-without-saving-button-container{
  text-align:center;
}
.spark-save-button-container{
  text-align:center;
}


/* QUIZ STUFF /*

/* Add this CSS to your stylesheet or a CSS-in-JS solution */

.question-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.question-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  flex-direction: column;
}

.option {
  cursor: pointer;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
}

.option:hover {
  background-color: #e6f7ff;
  border-color: #80bfff;
}

.option:active {
  background-color: #cceeff;
  border-color: #3399ff;
}

.response-container {
  font-size: 18px;
  margin-top: 20px;
}

.response-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.response-button:hover {
  background-color: #0056b3;
}

.response-button:active {
  background-color: #004494;
}


.content-container{
  padding-left:20px;padding-right:20px;
}


.tutorial-header3{
  background-color: #4a47f9;
  color:white;
  
}

.tutorial-header3 img{
  padding-bottom:-20px;
  margin-bottom:-4px;
  padding:10px;
  padding-bottom:0;
  width:150px;
  
}

.project_container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.project_step {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.project_step_heading {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.project_step_description {
  margin-bottom: 10px;
  line-height: 1.6;
  color: #555;
}

.project_step_images {
  display: block;
  gap: 10px;
}

.project_image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Supplies Section Specific */
.project_supply {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.project_supply_name {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #555;
}

.project_supply_images {
  display: block; /* Stacks images vertically */
  margin-top: 10px;
  gap: 10px;
}

.project_all_materials img {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.errorMessage{color:red;}

.disabled-button {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Darker gray text */
  cursor: pointer; /* Allow pointer, but gray implies inactive */
  pointer-events: auto; /* Ensure it's clickable */
}

.disabled-button:active {
  background-color: #bbb; /* Slightly darker gray when clicked */
}


.project_tutorial-body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}

.project_tutorial-header {
  color: #2c3e50;
}

.project_tutorial-intro {
  margin-bottom: 20px;
}

.project_tutorial-step {
  margin-bottom: 30px;
}

.project_tutorial-step-header {
  color: #2c3e50;
}

.project_tutorial-list {
  margin: 10px 0;
  padding-left: 20px;
}

.project_tutorial-image {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.project_tutorial-troubleshooting {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}


