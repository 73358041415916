.main-container {
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fefefe;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.standard-text-container {
    font-size: 1.1em;
    line-height:1.4;
}

.invite-logo{
    width:80px;
}

.header-image {
    max-width: 200px;
  }

  .header-image-container {    
    text-align:center;
    margin-bottom:20px;
    
  }

  .highlight-orange{color:orange; font-weight:bold;}

.important-details-header,
.interest-header {
    color: rgb(225, 161, 41);;
    margin-top: 30px;
}

.details-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.details-list li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
    line-height: 1.6;
  
}

.details-list li::before {
    content: '•';
    color: rgb(225, 161, 41);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.5em;
}

.invite-step1 {
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    padding: 30px;
    border: 4px solid #4a47f9;
    border-radius: 10px;
    background-color: #ffffff;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intro-text {
    font-size: 1.2em;
    font-weight: bold;
  
    margin-bottom: 10px;
}

.highlight-text {
    font-size: 1.1em;
    color: rgb(225, 161, 41);
    margin-bottom: 20px;
}

.description {
    line-height: 1.6;
 
    margin-bottom: 20px;
}

.activity-header {
    color: rgb(225, 161, 41);
    margin-top: 30px;
    margin-bottom: 20px;
}

.details-text {
    margin: 10px 0;
    line-height: 1.6;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.step-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #4a47f9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.step-button:hover {
    background-color: #1f1c9b;
}

.radio-buttons-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
}

.radio-option {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    transition: background 0.3s ease, border-color 0.3s ease;
}

.radio-option.selected {
    background: #e6f7ff;
    border-color: #91d5ff;
}

.radio-option input {
    margin-right: 10px;
}



.interest-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.interest-table th, .interest-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.interest-table th {
    background-color: #f2f2f2;
    color: #333;
}

.interest-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.interest-table tr:hover {
    background-color: #f1f1f1;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

label {
    margin-bottom: 5px;
    color: #555;
}

.input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.input-field:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.landing-page-container-sparkers-logo{
    width:80px;
}



.info-box {
  
    width: 80%; /* Make it take up 80% of the screen width */
    background-color: #060672e0; /* Dark blue background */
    color: white; /* White text */
    padding: 10px; /* Add some padding for better aesthetics */
    text-align: center; /* Align text to the right */
    border-radius: 15px; /* Round the top left corner */
    border-radius: 15px; /* Round the bottom left corner */
    margin-right:auto; margin-left:auto;
    margin-bottom:20px;
}

