.ContentContainer {
    padding:20px;
  }

  .ContentContainer img {
    width: 90%;
    height: auto; /* Maintain the aspect ratio */
    padding:20px;
  }

  .ArticleContainer{
    
  }

  .ArticleBaseContainer{
   
  }

  .ArticlePageContainer{
    
    padding:20px; margin:20px;
  }

  .ArticleParagraphContainer{
    margin-bottom:20px;
    text-align: left;
  }


  .ArticleText{
    font-size:50px;
    color:red;
  }



