.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width:80%;
  text-align: center;
}

.modal-content img {
 width: 150px;
 margin-right:auto;margin-left:auto;
}

.article-special-text{
  color:orange;
  font-weight:bold;
}


.filled-div {
  background-image: linear-gradient(to right, orange 30%, transparent 30%);
}


.progress-bar-container {
  
  align-items: center;
}

.progress-bar {
  width: 100%; /* Adjust the width of the progress bar as needed */
  height: 50px; /* Adjust the height of the progress bar as needed */
  border: 1px solid #ccc;
}

.percentage {
  margin-left: 10px;
}



.onboarding-container-sparkers img{
  width:100px;
}

.login-name-input{
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease-in-out;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}


/* Styles for Onboarding component */
.onboarding-container-sparkers {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: center;
}

.onboarding-container-sparkers h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.onboarding-container-sparkers h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.onboarding-container-sparkers p {
  font-size: 1.1em;
  margin-bottom: 15px;
}





.onboarding-container-sparkers-logo {
 width:120px;
}

.landing-page-container-sparkers-logo {
  width:70px;
 }

 .login-name-input{
  width: 80%; /* Set the width of the input */
  height: 40px; /* Set the height of the input */
  font-size: 40px; /* Set the font size of the text inside */
  margin:20px;
 }

.onboarding-container-sparkers-choose-avatar-avatar {
  width: 60px; 
  border: 5px solid rgb(251, 255, 0);
  border-radius: 100px;
  margin: 3px;
  background-color: white;
  transition: transform 0.2s ease-in-out;
}

.onboarding-container-sparkers-choose-avatar-avatar:hover {
  transform: scale(1.1); /* Scale the avatar by 10% on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.onboarding-container-sparkers-choose-avatar-avatar:active {
  transform: scale(0.9); /* Scale the avatar by 10% on click */
  background-color:blue;
}

.green-lightning-container{
  width: 130px;
  
}

.student-info-table {
  width: 100%; /* Full width for better alignment */
  border-collapse: collapse; /* Removes unnecessary space between table borders */
  margin: 10px 0; /* Adds some space above and below the table */
}

.student-info-table td {
  padding: 8px 12px; /* Adds spacing within cells */
  border: 1px solid #ddd; /* Light border around cells */
  text-align: left; /* Aligns text to the left for readability */
}

.student-info-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row colors for better readability */
}

.student-info-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.student-info-table b {
  font-weight: bold; /* Ensures bold labels stand out */
}
