.componentContainer {
  background-color: #ffffff;
  text-align: center;
  padding:20px;
}

.loginNameInput {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease-in-out;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.loginNameInput:focus {
  border-color: #5b9bd5;
  outline: none;
  box-shadow: 0 0 5px rgba(81, 167, 232, 0.5);
}
