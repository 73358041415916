.ProjectListItem {
  /* Add your styles here */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  margin: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width:80%;
}

.ProjectListItem:hover {
  /* Add your styles here */
  background-color: #e4eea071;
  
}

.ProjectListItemInsideTop {
  /* Add your styles here */
  font-size: 46px;
  
  margin-bottom: 8px;
}

.ProjectListItemInsideMiddle {
  /* Add your styles here */
  font-size: 18px;
  font-weight: bold;
  color: #444;
  margin-bottom: 8px;
}

.ProjectListItemInsideBottom {
  /* Add your styles here */
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
}

.ProjectComponentButtonContainerMain {
  /* Add your styles here */
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.ProjectComponentButtonContainerLeft {
  /* Add your styles here */
  margin-right: 8px;
}

.PrimaryButton {
  /* Add your styles here */
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.PrimaryButton:hover {
  /* Add your styles here */
  background-color: #0056b3;
}

.ProgressBarContainer{

}

.ProgressBar{

}