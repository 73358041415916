@import url('../../../Shared/colors.css');
.NavButton{margin-right: 5px;
  margin-left: 5px;
  border:0;
  border-radius: 0 0 20px 20px; /* top-left, top-right, bottom-right, bottom-left */
   background-color:#e9dee3;}

   .NavButtonActive{margin-right: 5px;
    margin-left: 5px;
    border:0;
    border-radius: 0 0 20px 20px; /* top-left, top-right, bottom-right, bottom-left */
    background-color:var(--main-primary-color);}

