body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sign-up-button {
  display: inline-block;
  background-color: #ffa500;
  color: #ffffff;
  padding: 10px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sign-up-button:hover {
  background-color: #e59400;
}

.scaled-image {
  max-width: 200px;
}




