@import url('../../../Shared/colors.css');

.ProfileHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:var(--main-primary-color);
  padding-bottom:0;
  padding-top:10px;
  margin-top:0;
}

.object {
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-bottom:0;
}



.middle {
  font-size: 24px;
  font-weight: bold;
  color: coral;
  margin-left: 20px;
  margin-right: 20px;
}

.ProfileHeaderLogo {
  height:80px;
}

.right {
  margin-left: 0;
}
.left {
  margin-right: 0;

  display: flex;
  flex-direction: column; /* Arrange items vertically */
  justify-content: flex-end; 
}

.text {
  /* Add any additional styles if needed */
}


.ProfileTitleText{
  color: white;
  font-weight:bold;
  font-size:28px;
}

.ProfileTitleSubtext{
  color: white;
  font-size:12px;
}
