:root {
    --main-primary-color: #4a47f9; /* Define CSS variable */
    --main-secondary-color: rgb(255, 255, 255); /* Define CSS variable */
    --main-green-color: #28d757; 
    --main-orange-color: rgb(225, 161, 41); 
    --main-blue-color: #4a47f9;
    
  
    --main-button-color: rgb(225, 161, 41); 
    --main-button-color-hover: rgb(245, 209, 143); 
  }