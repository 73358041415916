.BookshelfItem {
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s ease;
  
}

.BookshelfItem:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ContentItemIcon {
  width: 50px;
  height: 50px;
}

.ContentTitle {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}



.BookshelfContainer{
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;

  border-radius: 5px;
}

.BookshelfTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.BookshelfContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}
